body { margin: 0; font-family: Arial, sans-serif; }
.container { max-width: 1200px; margin: 0 auto; padding: 0 20px; }
header { position: fixed; top: 0; width: 100%; background-color: #333; color: white; padding: 15px 0; z-index: 999; }
header .container { display: flex; justify-content: space-between; align-items: center; }
nav ul { list-style-type: none; margin: 0; padding: 0; }
nav ul li { display: inline; margin-right: 20px; }
nav ul li a { color: white; text-decoration: none; }
section { min-height: 100vh; padding: 100px 0; scroll-margin-top: 60px; }
section .container { max-width: 800px; }
footer { background-color: #333; color: white; padding: 15px; text-align: center; }

.dark-mode { background-color: #333; color: white; }
.light-mode { background-color: white; color: black; }

.icon-button { background: none; border: none; color: white; font-size: 24px; cursor: pointer; margin-left: 10px; }

@media screen and (max-width: 768px) {
  header .container { flex-direction: column; }
  nav ul li { display: block; margin: 10px 0; }
}

@media screen and (max-width: 480px) {
  section { padding: 80px 0; }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
       only screen and (min--moz-device-pixel-ratio: 2),
       only screen and (-o-min-device-pixel-ratio: 2/1),
       only screen and (min-device-pixel-ratio: 2),
       only screen and (min-resolution: 192dpi),
       only screen and (min-resolution: 2dppx) {
  /* Retina-specific styles */
  body { -webkit-font-smoothing: antialiased; }
}

.content-wrapper {
  white-space: pre-wrap;
  text-align: justify;
}